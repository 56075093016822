import { useState, useEffect, useCallback } from 'react'
import { MapContainer, TileLayer, Marker, Circle, Popup, useMapEvents, LayersControl, FeatureGroup } from 'react-leaflet'
import { InputNumber, Form, Modal, Cascader, Button, message } from 'antd'
import { EditControl } from "react-leaflet-draw"

import Cropper from 'react-easy-crop'

import L from 'leaflet'
import * as htmlToImage from "html-to-image"

import getCroppedImg from './CropImage'
import { useLocalizacao } from '../providers/Localizacao';
import { useLogin } from '../providers/Login';

import 'react-image-crop/dist/ReactCrop.css'

let setGlobalMarkers
let storeMarkers
let posteSelecionado

const zoomStored = parseInt(localStorage.getItem('zoom')) || 18
const latlng = JSON.parse(localStorage.getItem('latlng')) || { lat: -5.51621388888889, lng: -47.4790844444444 }
const { BaseLayer } = LayersControl

const iconeCasa = L.icon({
	iconUrl: 'imagens/casa.png',
	iconSize: [30, 30],
	iconAnchor: [10, 10]
})

const iconePoste = L.icon({
	iconUrl: 'imagens/poste.png',
	iconSize: [30, 30],
	iconAnchor: [10, 10]
})

const iconePosteCheio = L.icon({
	iconUrl: 'imagens/poste_vermelho.png',
	iconSize: [30, 30],
	iconAnchor: [10, 10]
})

const fillBlueOptions = { fillColor: 'blue' }


const googleMapsApiKey = 'AIzaSyB9uXNt9MrAlufej5GD3ygmdy_3J1nO1i8'
const streetViewPanoramaOptions = {
	position: null,
	pov: {heading: 100, pitch: 0},
	zoom: 1
}

function LocationMarker() {

	const { localizacao, setarLocalizacao } = useLocalizacao()

	streetViewPanoramaOptions.position = localizacao
	
	useEffect(() => {
		if (localizacao.lat && localizacao.lng) {
			map.flyTo(localizacao, 18)
			fetch(`https://api.jupiter.com.br/view/Geogrid/retornarTerminaisProximosV2?latitude=${localizacao.lat}&longitude=${localizacao.lng}`)
				.then(response => response.json())
				.then(data => {
					if (data.success) {
						setGlobalMarkers(data.terminais)
						storeMarkers = data.terminais
					}
				}
				);
		}
	}, [localizacao]);

	const map = useMapEvents({
		click(e) {
			map.flyTo(e.latlng, 21)
			setarLocalizacao(e.latlng)
		},
		zoomend(e) {
			localStorage.setItem('zoom', e.target._zoom)
		},
		moveend(e) {
			localStorage.setItem('latlng', JSON.stringify(map.getCenter()))
		}
	})

	return localizacao === null ? null : (
		<Marker icon={iconeCasa} position={localizacao}>
			<Popup>You are here</Popup>
		</Marker>
	)
}

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {

	const [form] = Form.useForm()
	const [markers, setMarkers] = useState([])
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [objetoPoste, setObjetoPoste] = useState({})
	const [centerCircle, setCenterCircle] = useState(null)
	const [streetView, setStreetView] = useState(null)

	const [mapCrop, setMapCrop] = useState(null)
	const [rotation, setRotation] = useState(0)
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
	const [croppedImage, setCroppedImage] = useState(null)

	const [crop, setCrop] = useState({ x: 0, y: 0 })
	const [zoom, setZoom] = useState(1)

	const { login, setarLogin } = useLogin()

	setGlobalMarkers = setMarkers

	const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
		console.log(croppedArea, croppedAreaPixels)
		setCroppedAreaPixels(croppedAreaPixels)
	}, [])

	const showCroppedImage = useCallback(async () => {
		try {
			const croppedImage = await getCroppedImg(
				mapCrop,
				croppedAreaPixels,
				rotation
			)
			console.log('donee', { croppedImage })
			setCroppedImage(croppedImage)
			setIsModalVisible(true)

		} catch (e) {
			console.error(e)
		}
	}, [croppedAreaPixels, rotation])

	const limpar = () => {
		setIsModalVisible(false)
		setMapCrop(null)
		setCroppedImage(null)
		setCenterCircle(null)
		setMarkers(storeMarkers)
	}

	const handleOk = () => {
		form.validateFields().then(values => {

			let _data = { ...values }
			_data['mapa'] = croppedImage
			_data['terminalSelecionado'] = objetoPoste.codigo
			_data['splitter'] = objetoPoste.splitters.find(el => el.rede = values.rede)
			_data['usuario'] = login.usuario

			fetch('https://api.jupiter.com.br/action/OrdemDeServico2/salvarViabilidadeVirtual', {
				method: "POST",
				body: JSON.stringify(_data),
				headers: { "Content-type": "application/json" }
			}).then(response => response.json())
				.then(json => {
					if (json.success) {
						message.info('This is a normal message')
						form.resetFields()
						limpar()
					} else {
						message.error(json.message)
					}
				})
				.catch(err => console.log(err))

		}).catch(info => {
			console.log('Validate Failed:', info)
		});
	}

	const handleCancel = () => {
		form.resetFields()
		limpar()
	}

	const clickMarker = (e) => {
		posteSelecionado = e.target.options.id

		const marker = markers[e.target.options.id]
		marker.splitters = marker.splitters.filter(v => v.splitter_cheio === '0')
		setCenterCircle([e.latlng.lat, e.latlng.lng])
		setObjetoPoste(marker)
		setMarkers([marker])

		htmlToImage
			.toPng(document.getElementById("mapa"))
			.then(function (dataUrl) {
				setMapCrop(dataUrl)
			})
			.catch(function (error) {
				console.error("oops, something went wrong!", error)
			})

	}

	return (
		<>
			{!mapCrop && <MapContainer id="mapa" onClick={e => setStreetView(e)} center={[latlng.lat, latlng.lng]} zoom={zoomStored}>
				<LayersControl>
					<BaseLayer checked name="Mapa">
						<TileLayer
							url='https://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}'
							maxZoom={20}
							subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
						/>
					</BaseLayer>
					<BaseLayer name="Satélite">
						<TileLayer
							url='https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
							maxZoom={20}
							subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
						/>
					</BaseLayer>
				</LayersControl>
				<FeatureGroup>
					<EditControl
						position='bottomright'
						onEdited={(e) => console.log(e)}
						onCreated={(e) => console.log(e)}
						onDeleted={(e) => console.log(e)}
						onDrawStop={(e) => console.log(e)} // L.GeometryUtil.readableDistance }
						draw={{ rectangle: false }}
					/>
				</FeatureGroup>
				<LocationMarker />
				{centerCircle && <Circle center={centerCircle} pathOptions={fillBlueOptions} radius={16} />}
				{markers.map((el, idx) => <Marker id={idx} eventHandlers={{ click: (e) => clickMarker(e) }} icon={el.caixa_cheia === '1' ? iconePosteCheio : iconePoste} key={`marker-${idx}`} position={[el.latitude, el.longitude]}><Popup>{el.codigo}</Popup></Marker>)}
			</MapContainer>}
			<Modal title={objetoPoste.codigo} visible={isModalVisible} okText="Salvar" cancelText="Cancelar" onOk={handleOk} onCancel={handleCancel}>
				<Form form={form} layout="horizontal" labelCol={{ span: 8, }} wrapperCol={{ span: 16, }} initialValues={{ remember: true, }} autoComplete="off">
					<Form.Item label="Ordem de Serviço" name="ordemdeservico" rules={[{ required: true, message: 'Por favor preencha o número da ordem de serviço!', },]}>
						<InputNumber />
					</Form.Item>
					<Form.Item name="sistema" label="Sistema" rules={[{ type: 'array', required: true, message: 'Por favor selecione o sistema!' },]}>
						<Cascader options={[
							{ value: '_imperatriz', label: 'Imperatriz' },
							{ value: '_acailandia', label: 'Açailândia' },
							{ value: '_augustinopolis', label: 'Augustinopólis' },
							{ value: '_dom_eliseu', label: 'Dom Eliseu' },
							{ value: '_grajau', label: 'Grajaú' },
							{ value: '_maraba', label: 'Marabá' },
							{ value: '_parauapebas', label: 'Parauapebas' },
							{ value: '_redencao', label: 'Redencão' },
							{ value: '_tucurui', label: 'Tucuruí' },
							{ value: '_santa_ines', label: 'Santa Inês' },
							{ value: '_araguaina', label: 'Araguaína' },
						]} />
					</Form.Item>
					<Form.Item name="tipoPlano" label="Tipo de Plano" rules={[{ type: 'array', required: true, message: 'Por favor selecione o tipo de plano!' },]}>
						<Cascader options={[
							{ value: 0, label: 'Plano Residencial' },
							{ value: 1, label: 'Plano Empresarial' }
						]} />
					</Form.Item>
					<Form.Item name="rede" label="Rede" rules={[{ type: 'array', required: true, message: 'Por favor selecione a rede!' },]}>
						<Cascader fieldNames={{ label: 'nome_rede', value: 'rede', }} options={objetoPoste.splitters} />
					</Form.Item>
					<Form.Item label="Drop" name="drop" rules={[{ required: true, message: 'Por favor preencha a quantidade de drop!', },]}>
						<InputNumber />
					</Form.Item>
					<Form.Item label="Esticadores" name="esticadores" rules={[{ required: true, message: 'Por favor preencha a quantidade de esticadores!', },]}>
						<InputNumber />
					</Form.Item>
					<Form.Item label="Kit Óptico" name="kit_optico" rules={[{ required: true, message: "Por favor preencha a quantidade de Kit's ópticos!", },]}>
						<InputNumber />
					</Form.Item>
				</Form>
			</Modal>
			{mapCrop && !isModalVisible && <div className="crop-container">
				<Cropper
					image={mapCrop}
					crop={crop}
					zoom={zoom}
					aspect={4 / 3}
					onCropChange={setCrop}
					onCropComplete={onCropComplete}
					onZoomChange={setZoom}
				/>
				<br />
				<center>
					<Button type="primary" onClick={showCroppedImage}>Cortar Imagem</Button>
					&nbsp;
					<Button type="primary" onClick={limpar} danger>Cancelar</Button>
				</center>
			</div>}
		</>
	)
}